/* This example requires Tailwind CSS v2.0+ */
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { Fragment } from 'react';

import useLogout from '@/hooks/useLogout';

import LanguageSelector from '@/components/LanguageSelector/LanguageSelector';

import {
  LANGUAGE_ITEMS,
  PRODUCTS_MENU,
  SOLUTIONS_MENU,
} from '@/constants/HeaderItems';
import { USEFUL_LINKS } from '@/constants/UsefulLinks';
import useUser from '@/queries/useUser';

import HeaderPopoverMenu from './HeaderMenu';
import HeaderPopoverItem from './HeaderPopover';
import UnstyledLink from '../../links/UnstyledLink';
import NextImage from '../../NextImage';
import Logo from '../../ui/Logo/Logo';
import LogoName from '../../ui/LogoName/LogoName';

function LandingPageNavbar() {
  const { t, lang } = useTranslation('common');
  const { user } = useUser();
  const logOut = useLogout();

  return (
    <Popover className='bg-white dark:bg-stone-900'>
      <div className='mx-auto w-full'>
        <div className='flex items-center justify-between py-6 md:flex-wrap md:justify-center lg:flex-nowrap lg:justify-between'>
          <div className='-my-2 -mr-2 md:hidden'>
            <Popover.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-stone-400 hover:bg-stone-100 hover:text-stone-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:bg-stone-900'>
              <span className='sr-only'>{t('open-user-menu')}</span>
              <MenuIcon className='h-6 w-6' aria-hidden='true' />
            </Popover.Button>
          </div>
          <div className='flex justify-start md:flex-1 lg:flex-none'>
            <span className='sr-only'>Inkan</span>
            <div className='flex flex-col lg:items-center'>
              <UnstyledLink href='/'>
                <Logo className='hidden h-6 md:flex md:h-7' />
                <LogoName className='h-6 md:hidden md:h-7' />
              </UnstyledLink>
            </div>
          </div>
          <div className='-my-2 -mr-2 md:hidden'>
            {user ? (
              <Popover.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-stone-400 hover:bg-stone-100 hover:text-stone-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:bg-stone-900'>
                <NextImage
                  bgClassName='bg-white'
                  className='rounded-full'
                  height={35}
                  width={35}
                  src='/images/Avatar.svg'
                  alt=''
                />
              </Popover.Button>
            ) : (
              <div className='h-9 w-9' />
            )}
          </div>
          <Popover.Group
            as='nav'
            className='mx-0 hidden items-center font-medium text-inkanText md:order-2 md:mt-4 md:flex md:w-full md:flex-shrink-0 md:justify-evenly lg:order-1 lg:mt-0 lg:max-w-3xl lg:flex-1'
          >
            <HeaderPopoverItem itemText={t('products')} items={PRODUCTS_MENU} />
            <HeaderPopoverItem
              itemText={t('solutions')}
              items={SOLUTIONS_MENU}
            />

            <UnstyledLink
              href={USEFUL_LINKS.CONTACT_US}
              className='flex items-center font-normal hover:text-primary-500 dark:text-inkanLightGray dark:hover:text-white'
            >
              {t('contact-us')}
            </UnstyledLink>
            <UnstyledLink
              href={USEFUL_LINKS.CONTACT_US}
              className='flex items-center rounded-2xl border-[3px] border-inkanText px-4 py-1 font-normal hover:border-inkanBlue-500 hover:text-inkanBlue-500 dark:border-inkanLightGray dark:text-inkanLightGray dark:hover:border-white dark:hover:text-white'
            >
              {t('request-demo')}
            </UnstyledLink>
            <LanguageSelector />
          </Popover.Group>

          <div className='hidden items-center justify-end md:order-1 md:flex lg:order-2 '>
            {!user && (
              <>
                <UnstyledLink
                  href='/login'
                  className='mr-6 flex items-center text-base font-normal text-inkanText hover:text-primary-500 dark:text-inkanLightGray dark:hover:text-white'
                  data-testid='RegisterButton'
                >
                  {t('signin')}
                </UnstyledLink>
              </>
            )}
            {user && (
              <>
                <Popover as='div' className='relative flex-shrink-0'>
                  <div>
                    <Popover.Button
                      data-testid='UserMenu'
                      className='ml-6 flex overflow-hidden rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    >
                      <span className='sr-only'>{t('open-user-menu')}</span>
                      <NextImage
                        className='rounded-full'
                        height={35}
                        width={35}
                        src='/images/Avatar.svg'
                        alt=''
                      />
                    </Popover.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Popover.Panel className='absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      <UnstyledLink
                        href='/dashboard'
                        className='block w-full px-4 py-2 text-left text-sm text-stone-700'
                      >
                        {t('dashboard')}
                      </UnstyledLink>
                      <button
                        data-testid='SignOutButton'
                        onClick={() => logOut()}
                        className='block w-full px-4 py-2 text-left text-sm text-stone-700'
                      >
                        {t('signout')}
                      </button>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Small breakpoint menu */}

      <Transition
        as={Fragment}
        enter='duration-200 ease-out'
        enterFrom='opacity-0 scale-95'
        enterTo='opacity-100 scale-100'
        leave='duration-100 ease-in'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-95'
      >
        <Popover.Panel
          focus
          className='absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden'
        >
          <div className='divide-y-2 divide-stone-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-stone-900'>
            <div className='flex w-full flex-col items-center pb-6'>
              <div className='relative flex w-full items-center justify-between rounded-t-lg bg-white px-5 py-4 dark:bg-stone-900'>
                <div>
                  <Logo className='mr-2 h-4 md:mx-auto' />
                </div>

                <div className='z-20 -mr-2 flex items-center'>
                  <Popover.Button className='inline-flex items-center justify-center rounded-md p-2 text-inkanText hover:bg-primary-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:text-white'>
                    <span className='sr-only'>Close menu</span>
                    <XIcon className='h-6 w-6' aria-hidden='true' />
                  </Popover.Button>
                </div>
              </div>
              <div className='mt-8 w-full justify-center px-8'>
                <nav className='grid gap-y-8'>
                  <HeaderPopoverMenu
                    items={PRODUCTS_MENU}
                    itemText={t('products')}
                  />
                  <HeaderPopoverMenu
                    items={SOLUTIONS_MENU}
                    itemText={t('solutions')}
                  />

                  <UnstyledLink
                    href={USEFUL_LINKS.CONTACT_US}
                    className='flex items-center text-xl font-normal text-inkanText underline underline-offset-4 hover:text-primary-500 dark:text-inkanLightGray dark:hover:text-white'
                  >
                    {t('contact-us')}
                  </UnstyledLink>
                  <UnstyledLink
                    href={USEFUL_LINKS.CONTACT_US}
                    className='flex items-center text-xl font-normal text-inkanText underline underline-offset-4 hover:text-primary-500 dark:text-inkanLightGray dark:hover:text-white'
                  >
                    {t('request-demo')}
                  </UnstyledLink>

                  <HeaderPopoverMenu
                    itemText={lang === 'en' ? 'English' : 'Español'}
                    items={LANGUAGE_ITEMS}
                    buttons
                    className='mr-6'
                  />
                  {!user && (
                    <>
                      <UnstyledLink
                        href='/register'
                        className='mr-6 flex items-center text-xl font-bold text-inkanText hover:text-primary-500 dark:text-inkanLightGray dark:hover:text-white'
                        data-testid='RegisterButton'
                      >
                        {t('register')}
                      </UnstyledLink>
                      <UnstyledLink
                        href='/login'
                        className='mr-6 flex items-center text-xl font-bold text-inkanText hover:text-primary-500 dark:text-inkanLightGray dark:hover:text-white'
                        data-testid='RegisterButton'
                      >
                        {t('signin')}
                      </UnstyledLink>
                    </>
                  )}
                  {user && (
                    <div className='mt-6 flex items-center border-t pt-5'>
                      <div className='rounded-full'>
                        <NextImage
                          bgClassName='bg-white'
                          className='rounded-full'
                          height={35}
                          width={35}
                          src='/images/Avatar.svg'
                          alt=''
                        />
                      </div>

                      <button
                        data-testid='SignOutButton'
                        onClick={() => logOut()}
                        className={clsx(
                          'block w-full px-4 py-2 text-left text-xl font-bold  text-inkanText dark:text-inkanLightGray'
                        )}
                      >
                        {t('signout')}
                      </button>
                    </div>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default LandingPageNavbar;
